<template>
  <div class="relative top-0 left-0 right-0 bg-white">
    <div class="max-w-screen-xl mx-auto z-100 print:max-w-[720px] print:!px-0">
      <div class="flex w-full px-md xl:px-0">
        <div class="absolute flex items-center h-full">
          <BasicLink
            :link="parentPage"
            :icon-position="EPosition.LEFT"
            :label="t('repairshopForm.backLabel')"
            hash="#repairshop-basic"
            target="_self"
            icon="fas fa-chevron-left"
            class="text-vdv-base"
          />
        </div>

        <div class="ml-auto md:mx-auto">
          <NuxtLink to="/">
            <img
              v-if="!isMobileScreen"
              src="~/assets/images/van-der-ven-logo.svg"
              title="van der ven logo"
              alt="van der ven logo"
              class="h-[36px] my-3"
            />
            <img
              v-if="isMobileScreen"
              src="~/assets/images/vdv-v-bubble.svg"
              title="van der ven logo"
              alt="van der ven logo"
              class="h-[36px] my-3"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { EPosition } from '~/@types/basic-button';
import BasicLink from '@/components/components/core/basicLink/basic-link.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';

const { t } = useTrans();
const isMobileScreen = inject(mq_breakpointIsMobile);

const parentPage = computed(() => {
  const sourceUrl = useRouter().currentRoute.value.query['sourceUrl'] as string;
  if (sourceUrl) {
    return sourceUrl;
  }
  return '/';
});
</script>
