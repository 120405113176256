<template>
  <div class="w-full print:hidden bg-[#635652] text-white">
    <div
      class="max-w-screen-xl mx-auto z-100 grid grid-cols-1 lg:grid-cols-3 px-md xl:px-0 pb-[var(--sticky-btn-height)] lg:pb-0 touch:md:pb-0"
    >
      <div
        class="flex items-center h-full pt-md lg:pt-0 justify-self-center lg:justify-self-start"
      >
        <BasicLink
          :label="t('repairshopForm.backLabel')"
          target="_self"
          icon="fas fa-chevron-left"
          hash="#repairshop-basic"
          :link="parentPage"
          :icon-position="EPosition.LEFT"
        />
      </div>
      <div class="justify-self-center">
        <NuxtLink class="flex" to="/">
          <img
            src="~/assets/images/vdv-v-bubble-inverse.svg"
            title="van der ven logo"
            alt="van der ven logo"
            class="h-[36px] my-6 lg:my-3"
          />
          <img
            src="~/assets/images/vdv_footer_menschzumensch_claim_white.svg"
            title="van der ven claim"
            alt="van der ven claim"
            class="h-[36px] w-[194px] pl-xs my-6 lg:my-3"
          />
        </NuxtLink>
      </div>
      <div
        class="flex items-center h-full justify-self-center lg:justify-self-end pb-md lg:pb-0"
      >
        <BasicLink
          class="pr-md"
          link="/agb"
          :label="t('repairshopForm.footer.agb')"
          target="_blank"
        />
        <BasicLink
          class="pr-md"
          link="/datenschutz"
          :label="t('repairshopForm.footer.privacy')"
          target="_blank"
        />
        <BasicLink
          link="/impressum"
          :label="t('repairshopForm.footer.impressum')"
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EPosition } from '~/@types/basic-button';
import BasicLink from '@/components/components/core/basicLink/basic-link.vue';

const { t } = useTrans();
const parentPage = computed(() => {
  const sourceUrl = useRouter().currentRoute.value.query['sourceUrl'] as string;
  if (sourceUrl) {
    return sourceUrl;
  }
  return '/';
});
</script>
